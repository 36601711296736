import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from "vuelidate";

// Importing i18n and country flags for language menu - Enabling Internationalization
// import i18n from './translations/i18n'
import FlagIcon from 'vue-flag-icon';
import VueI18n from 'vue-i18n';
import {getTranslations} from "./services/backend.service";
import {getUrlParameter} from "./helpers/helpers";
Vue.use(VueI18n);

// Importing Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing Custom styles
import './scss/imports.scss';

// Importing Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBlind, faCheck, faTimes, faClock, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faBlind, faCheck, faTimes, faClock, faExclamationTriangle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const initVue = () => {
  // Get translations for the language passed in the url parameter
  const language = getUrlParameter('language') || 'en';
  let messages = {};
  getTranslations(language).then(response => {
    if(response.data){
      messages = {[language] : response.data};
    }
    else {
      // If data returned is not correct get translations from the hardcoded json files
      const locales = require.context('./translations/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
      locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const jsonFileLanguage = matched[1];
          messages[jsonFileLanguage] = locales(key);
        }
      });
    }

    const i18n = new VueI18n({
      locale: getUrlParameter('language') || 'en',
      fallbackLocale: getUrlParameter('language') || 'en',
      messages: messages,
    })

    Vue.use(FlagIcon);
    Vue.config.productionTip = false;
    Vue.use(Vuelidate);

    new Vue({
      router,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  });
};
initVue();