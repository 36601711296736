<template>
  <div id="app">
    <home-header v-if="inHomePage"></home-header>
    <div class="container">
      <div class="row">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  const homeHeader = () => import('./components/home/Header');

  export default {
    name: 'app',
    data(){
      return{
        inHomePage: false,
      }
    },
    mounted: function(){
      this.$router.beforeEach((to, from, next) => {
        this.inHomePage = to.path === '/';
        next();
      });
    },
    updated: function(){
      this.inHomePage = this.$router.history.current.path === '/';
    },
    components:{
      homeHeader
    },
  }

</script>