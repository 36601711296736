import axios from 'axios/index';

const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT_API,
  timeout: 10000
});

const headers = {
    'Content-Type': 'application/json'
};

const auth = {
    username: 'admin',
    password: 'testtest'
};

const getTranslations = (language) => {
    const url = `cms/data/${language}`;
    return AXIOS.get(url,
        {
            headers,
            auth,
    });
};

const getBankOptions = (requestPath) => {
    const url = requestPath;
    return AXIOS.get(url,
        {
            headers,
            auth
    });
};

const getWebPayData = (transactionId) => {
    const url = `paymentCentre/getDepositByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
    });
};

const getIkajoData = (transactionId) => {
    const url = `ikajo/getDepositByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};

const initCommunityBank = (currency, amount, processorName, processorType, customerId, ipAddress, brandUrl) => {
    const url = `luqapay/communityBank/init`;
    return AXIOS.post(
        url,
        {
            currency,
            amount,
            processorName,
            processorType,
            customerId,
            ipAddress,
            brandUrl,
        },
        {
            headers,
            auth
        });
};

const getCommunityBankAmounts = (swiftCode, paymentRef) => {
    const url = `luqapay/communityBank/availableAmounts`;
    return AXIOS.post(
        url,
        {
            swiftCode,
            paymentRef,
        },
        {
            headers,
            auth
        });
};

const payCommunityBank = (paymentRef, amount, currency, activeAmountId, userAgent, bankCode) => {
    const url = `luqapay/communityBank/pay`;
    return AXIOS.post(
        url,
        {
            paymentRef,
            amount,
            activeAmountId,
            currency,
            userAgent,
            bankCode,
        },
        {
            headers,
            auth
        });
};


const getCommercegateData = (transactionId) => {
    const url = `commercegate/getDepositByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};


const getCashtoCodeStatus = (transactionId) => {
    const url = `ecommpay/getCashtoCodeStatusByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
    });
};

const getPaysafeTransactionStatus = (transactionId) => {
    const url = `paysafe/getStatusByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
    });
};

const getCommercegateTransactionStatus = (transactionId) => {
    const url = `commercegate/getStatusByTransactionId/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};

const getCryptoData = data => {
    const url = `crypto/address/`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

const withdraw = (data) => {
    const url = `payment/initPayout/`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};


const cryptoWithdraw = (data) => {
    const url = `crypto/payout/`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

const getProviderUrl = (data) => {
    const url = `default/providerUrl/`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

const getReceiptData = (transactionId, params) => {
    let url = `payment/receipt/${transactionId}`;
    if(params !== 'undefined' && params !== undefined && params !== null){
        url = `${url}/?params=${params}`;
    }
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};

const getMopsFormData = (transactionId) => {
    let url = `mops/form/${transactionId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};

const getCustomerEmail = (customerId) => {
    let url = `/customer/getCustomer/${customerId}`;
    return AXIOS.get(url,
        {
            headers,
            auth
        });
};

const getPaymentIQData = (data) => {
    const url = `/api/paymentiq/init`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

const creditCardDeposit = (data) => {
    const url = `/default/process-deposit`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

const verifyToken = (data) => {
    const url = `secure/verify/`;
    return AXIOS.post(
        url,
        data,
        {
            headers,
            auth,
        });
};

export {getTranslations, getBankOptions, getWebPayData, getIkajoData, initCommunityBank, getCommunityBankAmounts, payCommunityBank, getCashtoCodeStatus,getPaysafeTransactionStatus,
    getCryptoData, withdraw, cryptoWithdraw, getProviderUrl, getReceiptData,getMopsFormData, getCommercegateData, getCustomerEmail,getPaymentIQData, creditCardDeposit, verifyToken};
