import Vue from 'vue'
import VueRouter from 'vue-router'

//New Components
const Guard = () => import('../components/new/guard/guard');
const CoinspaidDeposit = () => import('../components/new/coinspaid/coinspaid');
const CoinspaidWithdraw = () => import('../components/new/coinspaid/coinspaid-withdraw');
const PaymentIqDeposit = () => import('../components/new/payment-iq/payment-iq');
const PaymentIqWithdraw = () => import('../components/new/payment-iq/payment-iq-withdraw');
const CreditCardDeposit = () => import('../components/new/creditcard/creditcard');
const AsTurkeyDeposit = () => import('../components/new/asturkey/asturkey');
const LuqapayDeposit = () => import('../components/new/luqapay/luqapay');
const MaldopayDeposit = () => import('../components/new/maldopay/maldopay');
const VoltDeposit = () => import('../components/new/volt/volt');
const MandatoDeposit = () => import('../components/new/mandato/mandato');
const GenericWithdraw = () => import('../components/new/partials/withdraw');


const Home = () => import('../components/home/Home');
const PaymentCenterDeposit = () => import('../components/paymentMethods/wallet/PaymentCenterDeposit');
const IkajoDeposit = () => import('../components/paymentMethods/wallet/IkajoDeposit');
const CommercegateDeposit = () => import('../components/paymentMethods/wallet/CommercegateDeposit');
const EcommpayDepositLoader = () => import('../components/paymentMethods/wallet/EcommpayDepositLoader');
const PaysafeDepositLoader = () => import('../components/paymentMethods/wallet/PaysafeDepositLoader');
const ApcoDeposit = () => import('../components/paymentMethods/wallet/ApcoDeposit');
const EutellerDeposit = () => import('../components/paymentMethods/wallet/EutellerDeposit');
const GiropayDeposit = () => import('../components/paymentMethods/wallet/GiropayDeposit');
const SofortDeposit = () => import('../components/paymentMethods/wallet/SofortDeposit');
const JetonDeposit = () => import('../components/paymentMethods/wallet/JetonDeposit');
const LuqapayCommunityDeposit = () => import('../components/paymentMethods/wallet/LuqapayCommunityDeposit');
const NetellerDeposit = () => import('../components/paymentMethods/neteller/NetellerDeposit');
const NetellerWithdraw = () => import('../components/paymentMethods/neteller/NetellerWithdraw');
const Withdraw = () => import('../components/paymentMethods/withdraw/Withdraw');
const Receipt = () => import('../components/others/Receipt');
const ReceiptWithdraw = () => import('../components/others/ReceiptWithdraw');
const Mops = () => import('../components/others/Mops');
const Success = () => import('../components/others/Success');
const NotFound = () => import('../components/others/NotFound');
const Error = () => import('../components/others/Error');

Vue.use(VueRouter)

const getRoutes = () => {

  // Production routes
  let prodRoutes = [
    //----------------------------------------
    // Wallet Routes
    {
      path: '/deposit/paymentCenter',
      name: 'paymentCenterDeposit',
      component: PaymentCenterDeposit
    },
    {
      path: '/deposit/ikajo',
      name: 'ikajoDeposit',
      component: IkajoDeposit
    },
    {
      path: '/deposit/luqapay/community',
      name: 'luqapayCommunityDeposit',
      component: LuqapayCommunityDeposit
    },
    {
      path: '/deposit/commercegate',
      name: 'commercegateDeposit',
      component: CommercegateDeposit
    },
    //----------------------------------------
    // Wallet Ecommpay Deposit Loader
    {
      path: '/deposit/ecommpay/loader',
      name: 'ecommpayDepositLoader',
      component: EcommpayDepositLoader,
    },
    //----------------------------------------
    // Neteller Deposit
    {
      path: '/deposit/neteller',
      name: 'netellerDeposit',
      component: NetellerDeposit
    },
    //----------------------------------------
    // ----------------------------------------
    // Neteller Withdraw
    {
      path: '/withdraw/neteller',
      name: 'netellerWithdraw',
      component: NetellerWithdraw
    },
    //----------------------------------------
    // Wallet Paysafe Deposit Loader
    {
      path: '/deposit/paysafe/loader',
      name: 'paysafeDepositLoader',
      component: PaysafeDepositLoader,
    },
    //----------------------------------------
    // Wallet APCO Deposit
    {
      path: '/deposit',
      name: 'apcoDeposit',
      component: ApcoDeposit,
    },
    //----------------------------------------
    // Euteller Deposit
    {
      path: '/deposit/euteller',
      name: 'eutellerDeposit',
      component: EutellerDeposit,
    },
    //----------------------------------------
    // GiroPay Deposit
    {
      path: '/deposit/giropay',
      name: 'giropayDeposit',
      component: GiropayDeposit,
    },
    // SOFORT Deposit
    {
      path: '/deposit/sofort',
      name: 'sofortDeposit',
      component: SofortDeposit,
    },
    //----------------------------------------
    // Jeton Deposit
    {
      path: '/deposit/jeton',
      name: 'jetonDeposit',
      component: JetonDeposit,
    },
    //----------------------------------------
    // Generic Withdrawal
    {
      path: '/withdraw/',
      name: 'withdraw',
      component: Withdraw,
    },
    //----------------------------------------
    // Other Pages Routes - Receipt Page
    {
        path: '/receipt',
        name: 'receipt',
        component: Receipt
    },
    // Other Pages Routes - Receipt Page
    {
        path: '/receipt/:transactionid',
        name: 'receipt',
        component: Receipt
    },
    {
      path: '/receiptWithdraw',
      name: 'receiptWithdraw',
      component: ReceiptWithdraw
    },
    //----------------------------------------

    // ----------------------------------------
    // Other Pages Routes - Mops Page
    {
        path: '/mops',
        name: 'mops',
        component: Mops
    },
    //----------------------------------------

    // NEW Components after Guard/Token security was implemented
    {
      path: '/guard',
      name: 'guard',
      component: Guard
    },
    {
      path: '/deposit/crypto',
      name: 'CoinspaidDeposit',
      component: CoinspaidDeposit
    },
    {
      path: '/withdraw/crypto',
      name: 'CoinspaidWithdraw',
      component: CoinspaidWithdraw
    },
    {
      path: '/deposit/paymentiq',
      name: 'PaymentIqDeposit',
      component: PaymentIqDeposit
    },
    {
      path: '/withdraw/paymentiq',
      name: 'PaymentIqWithdraw',
      component: PaymentIqWithdraw
    },
    {
      path: '/deposit/creditCard',
      name: 'CreditCardDeposit',
      component: CreditCardDeposit
    },
    {
      path: '/deposit/asturkey',
      name: 'AsTurkeyDeposit',
      component: AsTurkeyDeposit
    },
    {
      path: '/withdraw/asturkey',
      name: 'AsTurkeyWithdraw',
      component: GenericWithdraw
    },
    {
      path: '/deposit/luqapay',
      name: 'LuqapayDeposit',
      component: LuqapayDeposit
    },
    {
      path: '/withdraw/luqapay',
      name: 'LuqapayWithdraw',
      component: GenericWithdraw
    },
    {
      path: '/deposit/maldopay',
      name: 'MaldopayDeposit',
      component: MaldopayDeposit
    },
    {
      path: '/withdraw/maldopay',
      name: 'MaldopayWithdraw',
      component: GenericWithdraw
    },
    {
      path: '/deposit/volt',
      name: 'VoltDeposit',
      component: VoltDeposit
    },
    {
      path: '/deposit/mandato',
      name: 'MandatoDeposit',
      component: MandatoDeposit
    },
  ];

  if(process.env.NODE_ENV !== 'production'){

    const devRoutes = [
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: '/success',
        name: 'success',
        component: Success
      },
      {
        path: '/notFound',
        name: 'notFound',
        component: NotFound
      },
      {
        path: '/error',
        name: 'error',
        component: Error
      },
    ];

    prodRoutes = [...prodRoutes, ...devRoutes]
  }
  return prodRoutes;
};

const router = new VueRouter({
  mode: 'history',
  routes : getRoutes(),
});

export default router;
